import "./reset.scss";
import "./App.scss";
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// Pages
import Home from "./pages/home.js";
import Portfolio from "./pages/portfolio.js";
import ContactUs from "./pages/contact.js";
import About from "./pages/about.js";
import ProjectDetail from "./pages/projectDetail.js";
import Gallery from "./pages/gallery.js";
import NotFound from "./pages/notFound.js";
//Components
import Top from "./components/top.js";
import Footer from "./components/footer.js";
import ScrollToTop from "./components/ScrollToTop";
function App() {
  return (
    <>
      <Helmet>
        <title>퍼블리셔프론트개발 | 올리브</title>
        <meta
          name="description"
          content="웹사이트, 홈페이지를 구현하는 프리랜서 퍼블리셔, 프론트엔드 개발합니다."
        />
      </Helmet>
      <div className="App wrap">
        <ScrollToTop />
        <Top />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/:param" element={<ProjectDetail />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}
export default App;
