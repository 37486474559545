import { configureStore, createSlice } from "@reduxjs/toolkit";

//프로젝트 웹 슬라이스
let myWeb = createSlice({
  name: "myWeb",
  initialState: [
    {
      id: 1,
      name: "어플리케이션 홍보를 위한 원페이지 반응형 제작",
      info: "사이트기획(100%) + 웹디자인(100%) + 웹퍼블리싱(100%)",
      detail:
        "2030 스마트 컨슈머를 겨냥한 포인트 어플리케이션을 홍보하게 위한 마케팅 랜딩페이지를 제작하였습니다. 디자인 툴 피그마를 활용하여 디자인을 기획, 구현하였습니다. 더불어 유저 인터렉션 스크롤 애니메이션을 기획하여 마케팅 타겟 맞춤 생동감있는 홍보 랜딩페이지를 구축하였습니다. 또한 어플리케이션 홍보페이지인 만큼 모바일에 맞춤화된 퍼블리싱을 진행하여 모바일 방문자의 유입을 목표로 제작하였습니다.",
      img: "pj_web1.gif",
      tag: [
        "광고용",
        "홍보용",
        "젊은",
        "스크롤 애니메이션",
        "반응형",
        "마케팅",
        "간단한",
        "앱",
      ],
      detailImg: ["pj_web1_01.jpg", "pj_web1_03.jpg", "pj_web1_04.jpg"],
      detailVid: [
        "https://www.youtube.com/embed/EmTeleBBGD8",
        "https://www.youtube.com/embed/iWDhORV-fWQ",
        "https://www.youtube.com/embed/NGVaR_jb_0U",
      ],
      link: "https://www.freelancer-kym.blog/myportfolio/web_v1/",
      date: "2024",
      summary: "keyTa WEB UX/UI WEB DESIGN AND PUBLISHING",
    },
    {
      id: 2,
      name: "SYNAPSE 회사 소개 스크롤 원페이지 반응형 제작",
      info: "사이트기획(100%) + 웹디자인(100%) + 웹퍼블리싱(100%)",
      detail:
        "회사 및 서비스 소개를 목적으로 한 원페이지 스크롤 기반의 웹사이트 디자인 및 웹퍼블리싱을 진행하였습니다. 스크롤 및 메뉴를 누를 때마다 영역이 부드럽게 넘어가도록 구현하였습니다. 각 컨텐츠에 맞는 웹사이트 동영상 배경을 삽입하여 차별화하였습니다.",
      img: "pj_web2.jpg",
      tag: ["광고용", "홍보용", "젊은", "스크롤 애니메이션", "반응형", "기업"],
      detailImg: ["pj_web2_01.jpg", "pj_web2_02.jpg"],
      detailVid: [
        "https://www.youtube.com/embed/P7xCyyCdM0Y",
        "https://www.youtube.com/embed/JwpZvJ5duZw",
      ],
      link: "https://www.freelancer-kym.blog/myportfolio/web_v2/",
      date: "2023",
      summary: "SYNAPSE SCROLL UX/UI WEB DESIGN AND PUBLISHING",
    },
  ],
});

//프로젝트 뉴스레터 슬라이스
let myNewsletter = createSlice({
  name: "myNewsletter",
  initialState: [
    {
      id: 11,
      name: "천연물연구소 KIST 협회 정기 뉴스레터 제작",
      info: "뉴스레터 디자인(100%) + 이메일 퍼블리싱(100%) + 이메일 대량발송대행",
      detail:
        "정기 구독 멤버 발송을 위한 협회 소식지 디자인 및 이메일 퍼블리싱을 진행하였습니다. 이메일 발송용 뉴스레터는 이메일 코딩을 고려하여 디자인 기획해야합니다. ",
      img: "pj_nl_1.jpg",
      tag: [
        "소식지",
        "협회소식지",
        "기관소식지",
        "정간지",
        "이메일",
        "뉴스레터",
        "정부기관단체",
      ],
      detailImg: ["nl_detail11.jpg"],
      date: "2022 ~ 2024",
      summary: "NEWSLETTER FOR an institutional organization",
    },
    {
      id: 12,
      name: "2019 부산특구 운영 사업 행사 안내 이메일 제작",
      info: "뉴스레터 디자인(100%) + 이메일 퍼블리싱(100%)",
      detail:
        "행사 및 컨퍼런스 안내 뉴스레터 디자인 및 이메일 코딩을 진행하였습니다. 링크로 연결되는 기능을 코드로 추가하여 많은 참여를 이끌었습니다.",
      img: "pj_nl_2.jpg",
      tag: ["행사", "컨퍼런스", "초대", "홍보", "광고", "이벤트"],
      detailImg: ["nl_detail12.jpg"],
      date: "2019",
      summary: "NEWSLETTER FOR an institutional organization",
    },
    {
      id: 13,
      name: "스킨바이브 미용, 의료 계열 홍보 뉴스레터 이메일 코드개발",
      info: "이메일 퍼블리싱(100%)",
      detail:
        "미용, 의료, 의학 홍보 및 레퍼런스 관련 뉴스레터 이메일 코딩 개발하였습니다.",
      img: "pj_nl_3.jpg",
      tag: ["의료", "미용"],
      detailImg: ["nl_detail13.jpg"],
      date: "2024",
      summary: "SKIN VIBE NEWSLETTER HTML PUBLISHING",
    },
    {
      id: 14,
      name: "kapco 2023 컨벤션전문가 인증 안내 이메일 제작 발행",
      info: "뉴스레터 디자인(100%) + 이메일 퍼블리싱(100%)",
      detail:
        "기관협회에서 진행하는 전문가 양상 인증 안내 이메일 디자인 및 이메일 html 코드개발을 진행하였습니다.",
      img: "pj_nl_4.jpg",
      tag: ["의료", "미용"],
      detailImg: ["nl_detail14.jpg"],
      date: "2023",
      summary: "NEWSLETTER FOR an institutional organization",
    },
    {
      id: 15,
      name: "삼성 메디슨 GI / OB 뉴스레터발송용 코드개발",
      info: "이메일 퍼블리싱(100%)",
      detail: "의료 뉴스레터 발송용 HTML 코드 개발 진행하였습니다.",
      img: "pj_nl_5.jpg",
      tag: ["의료", "의학", "대기업", "멤버", "소식지"],
      detailImg: ["nl_detail15.jpg"],
      date: "2023",
      summary: "NEWSLETTER FOR an institutional organization",
    },
    {
      id: 16,
      name: "삼성 메디슨 GI / OB 뉴스레터발송용 코드개발",
      info: "이메일 퍼블리싱(100%)",
      detail: "의료 뉴스레터 발송용 HTML 코드 개발 진행하였습니다.",
      img: "pj_nl_6.jpg",
      tag: ["의료", "의학", "대기업", "멤버", "소식지"],
      detailImg: ["nl_detail16.jpg"],
      date: "2023",
      summary: "NEWSLETTER FOR an institutional organization",
    },
  ],
});
//프로젝트 모션그래픽 슬라이스
let myMotion = createSlice({
  name: "myMotion",
  initialState: [
    {
      id: 220,
      name: "SYNAPSE 기업 홍보 영상 모션그래픽",
      info: "기획(100%) + 디자인(100%) + 모션(100%)",
      detail:
        "빠른 리듬의 기업 슬로건과 시장경쟁령 홍보 모션그래픽을 제작하였습니다.",
      img: "pj_mt220.jpg",
      detailImg: ["mt_detail220.jpg"],
      detailVid: ["https://www.youtube.com/embed/JwpZvJ5duZw"],
      date: "2024",
      summary: "Corporate Motion graphic",
    },
    {
      id: 221,
      name: "월드비전 프레임 디자인 동영상 템플릿 제작",
      info: "디자인(100%) + 모션(100%)",
      detail:
        "월드비전 영상 콘텐츠에 필요한 프레임 템플릿 제작을 담당하였습니다.",
      img: "pj_mt221.jpg",
      detailImg: ["mt_detail221.jpg"],
      detailVid: ["https://www.youtube.com/embed/vQV8bFW8dh4"],
      date: "2024",
      summary: "Corporate Motion graphic",
    },
    {
      id: 222,
      name: "이노레드 연말 gif 영상카드 제작",
      info: "전반적인 디자인 + 모션(100%)",
      detail:
        "이노레드 연말 브랜드 홍보를 위한 gif 모션그래픽 영상카드 제작을 담당하였습니다.",
      img: "pj_mt222.jpg",
      detailImg: ["mt_detail222.jpg"],
      detailVid: ["https://www.youtube.com/embed/irbEvAG5uWU"],
      date: "2024",
      summary: "Corporate Motion graphic",
    },
  ],
});
export default configureStore({
  reducer: {
    myWeb: myWeb.reducer,
    myNewsletter: myNewsletter.reducer,
    myMotion: myMotion.reducer,
  },
});
