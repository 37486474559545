import { useNavigate } from "react-router-dom";

function Footer() {
  let navigate = useNavigate();
  return (
    <>
      <footer>
        <div className="footer_menu ">
          <ul>
            <li>
              <button
                onClick={() => {
                  navigate("/about");
                }}
              >
                About me
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  navigate("/portfolio");
                }}
              >
                프로젝트 전체보기
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  navigate("/contact");
                }}
              >
                견적문의 보내기
              </button>
            </li>
          </ul>
        </div>
        <div className="footer_copyright">
          <span>
            COPYRIGHT 2024 웹디자이너 퍼블리셔 김유미 ALL RIGHTS RESERVED.
          </span>
          <ul>
            <li>
              <button
                className="f_sns_naver"
                onClick={() => {
                  window.open("https://blog.naver.com/webdesign_yumeekime");
                }}
              >
                <span>N</span>
              </button>
            </li>
            <li>
              <button
                className="f_sns_kakao"
                onClick={() => {
                  window.open("https://open.kakao.com/o/senOqzpb");
                }}
              >
                <span>K</span>
              </button>
            </li>
            <li>
              <button
                className="f_sns_youtub"
                onClick={() => {
                  window.open("https://www.youtube.com/@user-gn7vl8kj9h");
                }}
              >
                <span>Y</span>
              </button>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
}
export default Footer;
