import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux"; //store에서 데이터 가져오기 훅
import Left from ".././components/left.js";

function ProjectDetail() {
  let navigate = useNavigate();

  // Redux store 데이타 불러옴
  let getStoreData = useSelector((state) => {
    return state;
  });

  //url 파라미터 주소 설정
  let { param } = useParams();
  // 해당 프로젝트 슬라이스 찾기
  let projectSlice = null;
  // 파라미터가 200보다 작으면 웹 프로젝트에서, 200보다 크면 모션 프로젝트로 슬라이드 선언
  param < 200
    ? (projectSlice = getStoreData.myWeb)
    : (projectSlice = getStoreData.myMotion);
  console.log(getStoreData);
  let thisPageItem = projectSlice.find((item) => item.id == param);
  let imgContents = thisPageItem?.detailImg; //상세이미지리스트
  let vdContents = thisPageItem?.detailVid; //유투브비디오리스트
  return (
    <div className="project_detail container_grid" role="main">
      <Left />
      <div className="project_contents">
        <div className="project_intro">
          <h2>{thisPageItem.name}</h2>
          <p>{thisPageItem.detail}</p>
          <p>{thisPageItem.info}</p>
          {param > 200 ? (
            <></>
          ) : (
            <button
              onClick={() => {
                window.open(`${thisPageItem.link}`);
              }}
            >
              사이트보기
            </button>
          )}
        </div>

        <div className="project_images">
          {imgContents?.map(function (image, i) {
            return (
              <div key={i}>
                <figure key={i}>
                  <img
                    src={process.env.PUBLIC_URL + `/images/${imgContents[i]}`}
                    width="100%"
                    alt="프로젝트스크린샷"
                  />
                </figure>
                <div className="video_wrap">
                  <iframe
                    width="100%"
                    height="auto"
                    src={vdContents[i]}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            );
          })}
        </div>
        <div className="project_bottom">
          <h3>{thisPageItem.summary}</h3>
          <p>{thisPageItem.date}</p>
          <button
            onClick={() => {
              navigate("/portfolio");
            }}
          >
            목록으로 돌아가기
          </button>
        </div>
      </div>
    </div>
  );
}
export default ProjectDetail;
