import React from "react";

const GalleryCard = ({ title, children, summary, isOpen, onClick }) => {
  return (
    <div className="gallery-wrap" onClick={onClick}>
      <div className="gallery-title">
        <h2>{title}</h2>
      </div>
      {isOpen ? (
        <div className="gallery-detail">
          <p>{children}</p>
          <h3>{summary}</h3>
        </div>
      ) : null}
    </div>
  );
};

export default GalleryCard;
