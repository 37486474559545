import React from "react";
import { useNavigate } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
// import required modules
import { Autoplay, Scrollbar } from "swiper/modules";

import Left from ".././components/left.js";
// react gsap 적용
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

function Home() {
  let navigate = useNavigate();
  //gsap 스크롤 애니메이션
  useGSAP(() => {
    gsap.from(".home", {
      scale: 1.3,
      opacity: 1,
      ease: "ease.inOut",
    });
  });
  return (
    <>
      <div className="home container_grid" role="main">
        {/* 세로줄 */}
        <div className="column_item">
          <div className="project_slide">
            <h1>
              WEB
              <br />
              Design
              <br />& <br />
              Publishing
              <br />
              and so on...
            </h1>
            <span>Since 2017</span>
          </div>
          <div className="intro_contact">
            <button
              onClick={() => {
                navigate("/contact");
              }}
            >
              견적문의
            </button>
          </div>
        </div>
        <Left />
        {/* 세로줄 */}
        <div className="column_item">
          <div
            className="portfolio_banner"
            onClick={() => {
              navigate("/portfolio");
            }}
          >
            <p>포트폴리오 보러가기</p>
            <span>click ... 👆</span>
          </div>
          <div className="insta_banner">
            <div className="swiper-grid">
              <Swiper
                slidesPerView={1}
                scrollbar={{
                  hide: false,
                }}
                loop={true}
                autoplay={{
                  delay: 1800,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Scrollbar]}
                className="mySwiper"
              >
                <SwiperSlide
                  className="intro_sl1"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "/images/slide_is1.jpg"
                    })`,
                  }}
                >
                  <div className="insta_name">
                    <h3>약 226개의 크고 작은 프로젝트 완수했어요.</h3>
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  className="intro_sl1"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "/images/slide_is3.jpg"
                    })`,
                  }}
                >
                  <div className="insta_name">
                    <h3>Work hard and be nice to people.</h3>
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  className="intro_sl1"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "/images/slide_is2.jpg"
                    })`,
                  }}
                >
                  <div className="insta_name">
                    <h3>사기업, 공공기관, 정부기관과 프로젝트 진행</h3>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
