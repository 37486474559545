import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "bootstrap/dist/css/bootstrap.min.css";
//Components
import Left from ".././components/left.js";

function Portfolio() {
  let navigate = useNavigate();
  //프로젝트 카테고리별 탭 상태
  const [tap, setTap] = useState("웹사이트");
  const [modal, setModal] = useState(false); //뉴스레터 모달
  const [thisNl, setThisNl] = useState(); //뉴스레터 선택 관리

  // Redux store 데이타 불러옴
  let getStoreData = useSelector((state) => {
    return state;
  });
  //뉴스레터 선택에 따른 뉴스레터 데이터 : 모달창에 보여줄..
  const thisNewsletter = getStoreData.myNewsletter.find(
    (data) => data.id === thisNl
  );

  return (
    <>
      <div className="portfolio container_grid" role="main">
        <Left />
        <div className="portfolio_area">
          <div className="portfolio_tab">
            <h3>프로젝트</h3>
            <div className="portfolio_category">
              <Tabs
                id="controlled-tab-example"
                activeKey={tap}
                onSelect={(tap) => setTap(tap)}
                className="mb-3"
              >
                <Tab eventKey="웹사이트" title="웹사이트 홈페이지 웹페이지">
                  <div className="portfolio_gallery">
                    <div className="gallery_wrap">
                      {getStoreData.myWeb.map(function (project) {
                        return (
                          <div
                            key={project.id}
                            className="portfolio_box"
                            onClick={() => {
                              navigate(`/portfolio/${project.id}`);
                            }}
                          >
                            <div className="ptf_img_thumb">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/images/${project.img}`
                                }
                                width="100%"
                                alt="프로젝트썸네일"
                              />
                            </div>
                            <div className="ptf_txt_thumb">
                              <p>{project.name}</p>
                              <span>{project.info}</span>
                              <ul className="ptf_tags">
                                {project.tag.map(function (a, i) {
                                  return <li>{project.tag[i]}</li>;
                                })}
                              </ul>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="홍보이메일" title="뉴스레터, 홍보 이메일">
                  <div className="portfolio_gallery">
                    <div className="gallery_wrap">
                      {getStoreData.myNewsletter.map(function (project) {
                        return (
                          <div
                            key={project.id}
                            className="portfolio_box"
                            onClick={() => {
                              setThisNl(project.id);
                              modal === false
                                ? setModal(true)
                                : setModal(false);
                            }}
                          >
                            <div className="ptf_img_thumb">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/images/${project.img}`
                                }
                                width="100%"
                                alt="프로젝트썸네일"
                              />
                            </div>
                            <div className="ptf_txt_thumb">
                              <p>{project.name}</p>
                              <span>{project.info}</span>
                              <ul className="ptf_tags">
                                {project.tag.map(function (a, i) {
                                  return <li>{project.tag[i]}</li>;
                                })}
                              </ul>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Tab>
                <Tab
                  eventKey="홍보애니메이션"
                  title="모션그래픽, 홍보애니메이션"
                >
                  <div className="portfolio_gallery">
                    <div className="gallery_wrap">
                      {getStoreData.myMotion.map(function (project) {
                        return (
                          <div
                            key={project.id}
                            className="portfolio_box"
                            onClick={() => {
                              navigate(`/portfolio/${project.id}`);
                            }}
                          >
                            <div className="ptf_img_thumb">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  `/images/${project.img}`
                                }
                                width="100%"
                                alt="프로젝트썸네일"
                              />
                            </div>
                            <div className="ptf_txt_thumb">
                              <p>{project.name}</p>
                              <span>{project.info}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      {modal === true ? (
        <NewletterModal
          thisNewsletter={thisNewsletter}
          modal={modal}
          setModal={setModal}
        />
      ) : null}
    </>
  );
}

function NewletterModal({ modal, setModal, thisNewsletter }) {
  return (
    <div className="newsletter_modal">
      <div
        className="ns_modal_bg"
        onClick={() => {
          modal === false ? setModal(true) : setModal(false);
        }}
      ></div>
      <div className="modal_card">
        <div className="modal_close">
          <button
            onClick={() => {
              modal === false ? setModal(true) : setModal(false);
            }}
          >
            close
          </button>
        </div>
        <h2>{thisNewsletter.name}</h2>
        <p className="info_txt">{thisNewsletter.info}</p>
        <p>{thisNewsletter.detail}</p>
        <figure>
          <img
            src={process.env.PUBLIC_URL + `/images/${thisNewsletter.detailImg}`}
            width="100%"
            alt="프로젝트스크린샷"
          />
        </figure>
        <span>{thisNewsletter.date}</span>
        <span>{thisNewsletter.summary}</span>
      </div>
    </div>
  );
}

export default Portfolio;
