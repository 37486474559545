import { useNavigate } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Navigation } from "swiper/modules";

function Left() {
  let navigate = useNavigate();
  return (
    <>
      <div className="column_item left_intro">
        <div className="intro_notice">
          <h2>
            김유미 OLIVE
            <br />
            프리랜서
          </h2>
          <p>
            안녕하세요. <br />
            2018년도부터 UX/UI웹디자인, 퍼블리싱, 프론트엔드 개발과 뉴스레터,
            모션그래픽을 맡고있는 김유미입니다. <br />
            전화 상담이 실시간으로 어려울 수 있으니 문자나 이메일을 남겨주시면
            빠르게 답변드리겠습니다. <br />
            오늘도 행복한 하루되세요☺️
          </p>
        </div>
        <div className="intro_banner">
          <div class="swiper-grid">
            <Swiper
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide
                className="intro_sl1"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + "/images/intro_bg4.jpg"
                  })`,
                }}
                onClick={() => {
                  navigate("/portfolio/2");
                }}
              >
                <div className="intro_name">
                  <p>앱 어플리케이션 홍보웹페이지</p>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="intro_sl1"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + "/images/intro_bg1.jpg"
                  })`,
                }}
                onClick={() => {
                  navigate("/portfolio/2");
                }}
              >
                <div className="intro_name">
                  <p>반응형 기업소개 웹페이지</p>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="intro_sl1"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + "/images/intro_bg2.jpg"
                  })`,
                }}
                onClick={() => {
                  navigate("/portfolio");
                }}
              >
                <div className="intro_name">
                  <p>의료형 eDM 개발</p>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="intro_sl1"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + "/images/intro_bg3.jpg"
                  })`,
                }}
                onClick={() => {
                  navigate("/portfolio/221");
                }}
              >
                <div className="intro_name">
                  <p>테두리 디자인 영상제작</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="contact">
            <span>010.3006.0827</span>
            <span>yellomobile123@naver.com</span>
          </div>
        </div>
        <div className="intro_contact">
          <button
            onClick={() => {
              navigate("/contact");
            }}
          >
            견적문의
          </button>
        </div>
      </div>
    </>
  );
}
export default Left;
