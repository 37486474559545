import React, { useRef } from "react";
// react gsap 적용
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

function ClientsList() {
  //gsap 스크롤 애니메이션
  const clietns_box = useRef();
  useGSAP(() => {
    gsap.to(".clients_list", {
      y: -20,
      opacity: 1,
      stagger: 0.1,
      delay: 1,
    });
  });
  return (
    <>
      <div className="clients_lists" ref={clietns_box}>
        <h3>2018년부터 ... 프리랜서 웹디자이너 올리브를 믿어주신 분들,</h3>
        <ul>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client11.png"}
                alt="고객로고"
              />
            </div>
            <p>농림축산식품부</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client33.png"}
                alt="고객로고"
              />
            </div>
            <p>영화진흥위원회</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client19.png"}
                alt="고객로고"
              />
            </div>
            <p>삼성메디슨</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client24.png"}
                alt="고객로고"
              />
            </div>
            <p>HRDK</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client10.png"}
                alt="고객로고"
              />
            </div>
            <p>한국과학기술연구원(KIST)</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client25.png"}
                alt="고객로고"
              />
            </div>
            <p>Univcomms</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client26.png"}
                alt="고객로고"
              />
            </div>
            <p>사단법인 한국약제학회</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client22.png"}
                alt="고객로고"
              />
            </div>
            <p>한국PCO협회</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client18.png"}
                alt="고객로고"
              />
            </div>
            <p>래핑 카우 코리아</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client20.png"}
                alt="고객로고"
              />
            </div>
            <p>SK telecom</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client4.png"}
                alt="고객로고"
              />
            </div>
            <p>bridge of knowledge</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client21.png"}
                alt="고객로고"
              />
            </div>
            <p>한국자기학회</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client3.png"}
                alt="고객로고"
              />
            </div>
            <p>한샘</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client27.png"}
                alt="고객로고"
              />
            </div>
            <p>에비슨영코리아</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client1.png"}
                alt="고객로고"
              />
            </div>
            <p>행정안전부</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client28.png"}
                alt="고객로고"
              />
            </div>
            <p>한국번역연구소</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client9.png"}
                alt="고객로고"
              />
            </div>
            <p>프리오</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client15.png"}
                alt="고객로고"
              />
            </div>
            <p>이스턴네트웍스</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client8.png"}
                alt="고객로고"
              />
            </div>
            <p>한국정보산업연합회</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client6.png"}
                alt="고객로고"
              />
            </div>
            <p>비짓제주</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client29.png"}
                alt="고객로고"
              />
            </div>
            <p>&#40;주&#41;다이오진</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client30.png"}
                alt="고객로고"
              />
            </div>
            <p>&#40;주&#41;스캣커뮤니케이션</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client2.png"}
                alt="고객로고"
              />
            </div>
            <p>법무법인 지향</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client23.png"}
                alt="고객로고"
              />
            </div>
            <p>KLP IP FIRM</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client7.png"}
                alt="고객로고"
              />
            </div>
            <p>피카프로젝트</p>
          </li>
          <li className="clients_list">
            <div className="client_img">
              <img
                src={process.env.PUBLIC_URL + "/images/client13.png"}
                alt="고객로고"
              />
            </div>
            <p>라스트일마일</p>
          </li>
        </ul>
      </div>
    </>
  );
}
export default ClientsList;
