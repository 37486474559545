import React, { useRef } from "react";
// emailJs 적용
import emailjs from "@emailjs/browser";

function EmailForm() {
  // 이메일 js
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm("service_v9jkvug", "template_h3n4l2r", form.current, {
        publicKey: "AfVU1dFwWa0RIh-CA",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
    alert("문의가 전송되었습니다.");
  };
  return (
    <>
      <div className="contact_box">
        <form ref={form} onSubmit={sendEmail}>
          <label>Name</label>
          <input
            type="text"
            name="from_name"
            placeholder="보내시는 분의 이름을 적어주세요."
          />
          <label>Email</label>
          <input
            type="email"
            name="email"
            placeholder="답변받으실 이메일을 적어주세요."
          />
          <label>Message</label>
          <textarea
            name="message"
            placeholder="문의 내용을 적어주세요. 성실히 읽고 답변드릴게요"
          />
          <input type="submit" value="Send" />
        </form>
      </div>
    </>
  );
}
export default EmailForm;
