import React, { useState, useEffect } from "react";
//Components
import ClientsList from ".././components/clients_list.js";
import EmailForm from ".././components/email.js";

// react gsap 적용
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

function About() {
  //gsap 스크롤 애니메이션
  useGSAP(() => {
    gsap.to(".about_intro p", {
      y: -20,
      opacity: 1,
      delay: 1,
      stagger: 0.4,
    });
  });
  //설명 우측 이미지
  let [imgsrc, setImgsrc] = useState();
  // const onScroll = () =>{
  //   setImgsrc
  // };
  let [hereY, setHereY] = useState();
  const onScroll = () => {
    setHereY(window.scrollY);
    if (hereY < 1500) {
      setImgsrc("/images/aboutimg1.jpg");
    } else if (hereY >= 1500 && hereY < 2400) {
      setImgsrc("/images/aboutimg2.jpg");
    } else {
      setImgsrc("/images/aboutimg3.jpg");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
  }, [hereY]);

  return (
    <div className="about_page container_grid" role="main">
      <div className="about_intro">
        <h2>
          반갑습니다.
          <br />
          웹사이트 퍼블리셔, 프론트 개발
          <br />
          <span>
            {"("}4년차{")"}
          </span>{" "}
          김유미입니다.🤭
        </h2>
        <div className="about_txt">
          <p>UX, 프론트 개발,</p>
          <p>뉴스레터와 모션그래픽도 하고있어요.</p>
          <p>담당자, 기획자와 언제든 소통해요</p>
        </div>
      </div>
      <div className="project_area">
        <div className="project_head">
          <div className="project_txt">
            <h1>웹디자이너, 퍼블리셔를 찾고 계신가요?</h1>
            <p>웹사이트 기획안 디벨롭부터 . . .</p>
            <p>웹디자인과 . . .</p>
            <p>프론트 개발까지 . . .</p>
          </div>
          <div className="project_txt">
            <h1>뉴스레터, eDM 제작하시고 싶으시나요?</h1>
            <p>이메일 디자인과 이메일 발송 코딩 . . .</p>
            <p>대량발송 대행까지. . .</p>
          </div>
          <div className="project_txt">
            <h1>인포그래픽 홍보영상에 관심있으신가요?</h1>
            <p>모션그래픽으로 . . .</p>
            <p>홍보 효과를 극대화해요. . .</p>
          </div>
        </div>
        <div className="project_img">
          <div className="img_list_wrap">
            <img
              src={process.env.PUBLIC_URL + `${imgsrc}`}
              width="100%"
              alt="웹사이트프리랜서제작"
            />
          </div>
        </div>
      </div>
      <ClientsList />
      <EmailForm />
    </div>
  );
}

export default About;
