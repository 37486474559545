import { useNavigate } from "react-router-dom";

function NotFound() {
  let navigate = useNavigate();

  return (
    <div className="not_found">
      <h2>아무래도 url 주소를 잘못 입력하신 것 같습니다...</h2>
      <p>
        메인으로 가실까요?{" "}
        <a
          onClick={() => {
            navigate("/");
          }}
        >
          Click!
        </a>
      </p>
    </div>
  );
}

export default NotFound;
