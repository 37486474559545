import React, { useState, useEffect } from "react";
//Components
import EmailForm from ".././components/email.js";
import GalleryCard from ".././components/galleryCard.js";

function Gallery() {
  const [selectIdx, SetSelectIdx] = useState(null);

  const handleToggleCart = (index) => {
    SetSelectIdx(index);
  };

  return (
    <div className="gallery_page container_grid" role="main">
      <GalleryCard
        isOpen={selectIdx === 0}
        onClick={() => handleToggleCart(0)}
        title="홈페이지 제작, 프리랜서 퍼블리셔가 필요한 이유"
        summary=" 경험이 풍부한 프리랜서 퍼블리셔로서 고품질의 결과물을 제공하기
              위해 노력하고 있어요."
      >
        - 전문성 : 프리랜서 퍼블리셔는 웹 디자인 및 퍼블리싱 분야에서 전문적인
        기술과 경험을 갖추고 있어요. <br />- 유연한 일정 : 프리랜서 퍼블리셔는
        회사의 브랜드 이미지와 목표에 맞춘 맞춤형 디자인을 제공해요. 이는 회사의
        개성과 메시지를 효과적으로 전달할 수 있답니다.
        <br />- 합리적인 비용 : 풀타임 직원이나 대형 에이전시보다 프리랜서
        퍼블리셔를 고용하는 것이 비용 면에서 더 효율적! 필요할 때만 필요한
        작업을 의뢰하고, 고정적인 인건비를 줄여요. <br />- 개별 맞춤 서비스 :
        고객과의 직접적인 소통을 통해 프로젝트의 세부 사항을 이해할 수 있답니다.
      </GalleryCard>
      <GalleryCard
        isOpen={selectIdx === 1}
        onClick={() => handleToggleCart(1)}
        title="리액트로 홈페이지를 제작하는 프리랜서 프론트엔드"
        summary="웹사이트 퍼블리싱, 프론트 구축까지 진행하고 있어요."
      >
        React의 가장 큰 장점 중 하나는 컴포넌트 기반 아키텍처에요.
        <br />
        리액트로 홈페이지를 만들 때, 다양한 UI의 스타일을 통일하여 유연하고
        빠르게, 또 재사용 가능하게 관리할 수 있습니다.
        <br />
        리액트로 웹사이트 프론트를 구현하면 좋은 점은 많은 요소가 동적으로
        변하는 복잡한 애플리케이션도 성능 저하가 적어요. 상대적으로 페이지 로딩
        시간이 단축되어 사용자 경험을 향상시킬 수 있습니다.
      </GalleryCard>
      <GalleryCard
        isOpen={selectIdx === 2}
        onClick={() => handleToggleCart(2)}
        title="클라이언트의 요구사항에 맞춰 웹사이트 퍼블리싱, 프론트를 구축해요"
        summary="좀더 인터렉티브한 홈페이지 구축을 위해 노력합니다."
      >
        단순한 기업 홈페이지, 웹사이트 퍼블리싱은 그만,
        <br />
        홈페이지를 방문한 사용자와 커넥터블하며 인터렉티브한 기능을 구현합니다.
        <br />
        4년차 웹 퍼블리싱, 프론트 지원으로 고객의 요구사항에 맞춰 성실히
        진행하고 있어요.
        <br />
        2018년도부터 프리랜서 꾸준한 프리랜서 활동으로 신뢰와 경험을 쌓는 것을
        추구합니다.
      </GalleryCard>
      <EmailForm />
    </div>
  );
}

export default Gallery;
