import { useNavigate, Link } from "react-router-dom";

function Top() {
  let navigate = useNavigate();
  return (
    <>
      <header className="header">
        <div className="header_container">
          <nav>
            <ul className="nav_left">
              <li>
                <Link to="/about">ABOUT</Link>
              </li>
              <li>
                <Link to="/portfolio">PORTFOIO</Link>
              </li>
              <li class="nav_contact">
                <Link to="/contact">견적문의</Link>
              </li>
            </ul>
            <ul className="nav_right">
              <li>
                <Link to="/">HOME</Link>
              </li>
              <li>
                <Link to="/gallery">갤러리</Link>
              </li>
              <li>
                <button
                  onClick={() => {
                    navigate("/");
                  }}
                  class="global_btn"
                >
                  EN
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    window.open("https://open.kakao.com/o/senOqzpb");
                  }}
                  class="kakao_btn"
                >
                  오픈챗
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
}
export default Top;
