import React, { useRef } from "react";
// emailJs 적용
import emailjs from "@emailjs/browser";
//Components
import ClientsList from ".././components/clients_list.js";
import EmailForm from ".././components/email.js";

function ContactUs() {
  // 이메일 js
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm("service_v9jkvug", "template_h3n4l2r", form.current, {
        publicKey: "AfVU1dFwWa0RIh-CA",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <>
      <div className="contact_us container_grid" role="main">
        <div className="ttl_animation">
          <h2>언제든, 어디서든</h2>
          <h2>문의주세요</h2>
        </div>
        <p>성심성의껏 답변드리겠습니다.</p>
        <ClientsList />
        <EmailForm />
      </div>
    </>
  );
}

export default ContactUs;
